import { Flex, DatePicker, Form, TableColumnsType, InputNumber, Slider, Select, Button, Table, notification, Alert, Input, Space, Switch, Checkbox, Row, Col, Typography, Radio } from "antd";
import { useEffect, useState } from "react";
import { RetroService, DictionaryService, BacktestReportResponse, BacktestReportResponsePosition } from "shared/api";
import { TokenDto, useShowPopup } from "shared";
import dayjs from 'dayjs';
import numeral from "numeral";
import { ValidateStatus } from "antd/es/form/FormItem";
import { ChartCell } from "feature/chartCell";


const { RangePicker } = DatePicker;
const { Column, ColumnGroup } = Table;
const { Title, Text } = Typography;

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface FormModel {
  amountStable: number,
  ratioMin: number,
  ratioMax: number,
  token0Id: number,
  token1Id: number,
  channelWidthMin: number,
  channelWidthMax: number,
  exitTriggerMin: number,
  exitTriggerMax: number,
  poolChangeCountRangeMin: number,
  poolChangeCountRangeMax: number,
  switchDirection: boolean,
  center: boolean,
  apyMin: number,
  apyMax: number,
  timeframeList: Array<string>
  poolChangePrice: number,
  swapFee: string,
}

const mainColumns: TableColumnsType<BacktestReportResponse> = [
  {
    title: 'Channel width',
    dataIndex: 'channelWidth',
  },
  {
    title: 'Timeframe',
    dataIndex: 'timeframe'
  },
  {
    title: 'Fee paid',
    dataIndex: 'contractCallFees',
    render: (value)=> numeral(value ?? 0).format("0,0.00"),
  },
  {
    title: 'Fee earned',
    dataIndex: 'poolFeesIncome',
    render: (value)=> numeral(value ?? 0).format("0,0.00"),
  },
  {
    title: 'Hedge PNL',
    dataIndex: 'hedgePnL',
    render: (value)=> numeral(value ?? 0).format("0,0.00"),
  },
  {
    title: 'Ratio',
    dataIndex: 'ratio',
    render: (value) => {
      const ratio = Number(value) || 0;
      const complement = 100 - ratio;
      return `(${ratio}/${complement})`;
    }
  },
  {
    title: 'Amount w/o hedge',
    dataIndex: 'amountOut',
    sorter: (a, b) => (a.amountOut ?? 0) - (b.amountOut ?? 0),
    render: (value)=> numeral(value ?? 0).format("0,0.00"),
  },
  {
    title: 'Amount w hedge',
    dataIndex: 'amountOutWithHedge',
    sorter: (a, b) => (a.amountOutWithHedge ?? 0) - (b.amountOutWithHedge ?? 0),
    render: (value)=> numeral(value ?? 0).format("0,0.00"),
  },
  {
    title: 'Pool pstns',
    render: (obj:BacktestReportResponse)=> obj.positionList?.length,
  },
  {
    title: 'APY %',
    dataIndex: 'apyCalculated',
    render: (value)=> numeral(value ?? 0).format("0,0.00"),
  },
  {
    title: 'Amount chart',
    dataIndex: `chart`,
    align: 'center',
    render: (value) => <ChartCell points={value} />,
  },
]

const subColumns: TableColumnsType<BacktestReportResponsePosition> = [
  { title: 'DT Open', dataIndex: 'dtOpen', key: 'dtOpen', render: (value)=> dayjs(value ?? 0).format("DD.MM.YYYY HH:mm") },
  { title: 'DT Close', dataIndex: 'dtClose', key: 'dtClose', render: (value)=> dayjs(value ?? 0).format("DD.MM.YYYY HH:mm") },
  { title: 'PriceStart', dataIndex: 'priceIn', key: 'priceIn', render: (value)=> numeral(value ?? 0).format("0,0.00") },
  { title: 'PriceEnd', dataIndex: 'priceOut', key: 'priceOut', render: (value)=> numeral(value ?? 0).format("0,0.00") },
  //{ title: 'In', dataIndex: 'stableAmountIn', key: 'stableAmountIn', render: (value)=> numeral(value ?? 0).format("0,0.00") },
  {
    title: 'In',
    key: 'stableAmountIn',
    render: (text, record) => {
      const stableAmountIn = numeral(record.stableAmountIn ?? 0).format("0,0.00");
      return (
        <div>
          {stableAmountIn}
          <br />
          <Text type="secondary" style={{ fontSize: '12px' }}>{record.stableAmountInInfo}</Text>
        </div>
      );
    }
  },
  {
    title: 'Out',
    key: 'stableAmountOut',
    render: (text, record) => {
      const stableAmountOut = numeral(record.stableAmountOut ?? 0).format("0,0.00");
      return (
        <div>
          {stableAmountOut}
          <br />
          <Text type="secondary" style={{ fontSize: '12px' }}>{record.stableAmountOutInfo}</Text>
        </div>
      );
    }
  },
  { title: 'Hedge PnL', dataIndex: 'hedgePnL', key: 'hedgePnL', render: (value)=> numeral(value ?? 0).format("0,0.00") },
  { title: 'Trend', dataIndex: 'trend', key: 'trend' },
];
  
export const Backtest = () => {
  const [reportData, setReportData] = useState<BacktestReportResponse[]>([]);
  const [form] = Form.useForm<FormModel>();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [tokenLoading, setTokenLoading] = useState(true);
  const [isFormValid, setIsFormValid] = useState(true);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const openNotificationWithIcon = (type: NotificationType, description: string) => {
    api[type]({
      message: 'Notification',
      description
    });
  };
  const tokenFirstValue = Form.useWatch("token0Id", form);
  const tokenSecondValue = Form.useWatch("token1Id", form);

  const expandedRowRender = (record: BacktestReportResponse) => {
     return <Table columns={subColumns} dataSource={record.positionList??[]} pagination={false} />;
  };

  const updateDataTable = async (values: FormModel) => {
    setLoading(true);
    const result = await RetroService.getApiRetro({
      amountStable: values.amountStable,
      ratioMin: values.ratioMin,
      ratioMax: values.ratioMax,
      token0Id: values.token0Id,
      token1Id: values.token1Id,
      channelWidthMin: values.channelWidthMin,
      channelWidthMax: values.channelWidthMax,
      exitTriggerMin: values.exitTriggerMin,
      exitTriggerMax: values.exitTriggerMax,
      poolChangeCountRangeMin: values.poolChangeCountRangeMin,
      poolChangeCountRangeMax: values.poolChangeCountRangeMax,
      switchDirection: values.switchDirection,
      center: values.center,
      apyMin: values.apyMin,
      apyMax: values.apyMax,
      timeframeList: values.timeframeList,
      poolChangePrice: values.poolChangePrice,
      swapFee: values.swapFee
    });
    if (result && !result?.isSuccess) {
      openNotificationWithIcon('error', result?.errorText ?? "");
    }
    setLoading(false)
    setReportData(result.data!);
  }

  // Получить список токенов
  //const [tokens, setTokens] = useState<TokenDto[]>([]);
  const [token0Id, setTokensFirst] = useState<TokenDto[]>([]);
  const [token1Id, setTokensSecond] = useState<TokenDto[]>([]);
  useEffect(() => {
    form.setFieldsValue({
      amountStable: 1000,
      channelWidthMin: 5,
      channelWidthMax: 20,
      exitTriggerMin: 10,
      exitTriggerMax: 90,
      switchDirection: true,
      ratioMin: 20,
      ratioMax: 80,
      poolChangePrice: 0.02,
      timeframeList: ['week', 'month'],
      swapFee: '100',
    });
    RetroService.getApiRetroNettokens({
      // Hardcode Ethereum, т.к. нам нужны только метаданные
      netId: 1,
    }).then((result) => {
      //setTokens(result.data!);
      setTokensFirst(
        result.data?.filter((x) => x.isStableCoin === false) ?? []
      );
      setTokensSecond(
        result.data?.filter((x) => x.isStableCoin === true) ?? []
      );
      setTokenLoading(false);
    });
  },[]);

  // Define the handleValuesChange function
  const handleValuesChange = (
    changedValues: { ratioMin: number; ratioMax: number; exitTriggerMin: number; exitTriggerMax: number},
    allValues: { ratioMin: any; ratioMax: any; exitTriggerMin: any; exitTriggerMax: any }
  ) => {
    const { ratioMin, ratioMax, exitTriggerMin, exitTriggerMax } = allValues;

    const isValid = 
    exitTriggerMin < ratioMin &&
    ratioMin <= ratioMax &&
    ratioMax < exitTriggerMax;

    setIsFormValid(isValid);

    if (changedValues.ratioMin !== undefined) {
      if (ratioMin > ratioMax) {
        form.setFieldsValue({ ratioMax: ratioMin });
      }
    }
    if (changedValues.ratioMax !== undefined) {
      if (ratioMax < ratioMin) {
        form.setFieldsValue({ ratioMin: ratioMax });
      }
    }

    setIsFormValid(isValid);

    const errors = [];
    if (ratioMin < exitTriggerMin) {
      errors.push("Lower ratio value must be higher than lower exit level");
    }
    if (exitTriggerMin >= exitTriggerMax) {
      errors.push("Lower exit level must be less than higher one");
    }
    if (exitTriggerMax <= ratioMax) {
      errors.push("Higher ratio value must be lower than higher exit level");
    }

    setValidationErrors(errors);
  };

  return (
    <>
    {contextHolder}
    <div>Backtest</div>
    <Flex vertical>
    <Form form={form} layout="vertical" autoComplete="off" onFinish={updateDataTable} onValuesChange={handleValuesChange}>
      <Form.Item name="token0Id" label="Token 1" rules={[{ required: true}]}>
        <Select options={token0Id.map((d) => ({
            value: d.id,
            label: d.symbol,
          }))} loading={tokenLoading}>
        </Select>
      </Form.Item>
      <Form.Item name="token1Id" label="Token 2" rules={[{ required: true }]}>
        <Select options={token1Id.map((d) => ({
            value: d.id,
            label: d.symbol,
          }))} loading={tokenLoading}>
        </Select>
      </Form.Item>
      <Form.Item name="timeframeList" label="Timeframe" rules={[{ required: true }]}>
        <Checkbox.Group>
          <Row>
            <Col span={8}>
              <Checkbox value="twoyears" style={{ lineHeight: '32px' }}>
                2 years
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="year" style={{ lineHeight: '32px' }}>
                1 year
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="sixmonths" style={{ lineHeight: '32px' }}>
                6 months
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="threemonths" style={{ lineHeight: '32px' }}>
                3 months
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="month" style={{ lineHeight: '32px' }}>
                1 month
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="week" style={{ lineHeight: '32px' }}>
                1 week
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item label="Amount in stable" name="amountStable" rules={[{ required: true }]}>
        <InputNumber addonAfter="$" />
      </Form.Item>
      <Space direction="vertical">
        {validationErrors.length > 0 && (
          <Alert
            message="Validation Error"
            description={
              <ul>
                {validationErrors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            }
            type="error"
            showIcon
            style={{ marginBottom: "16px" }}
          />
        )}
        <Text>Position and hedge entrypoint range, aka ratio (%)</Text>
        <Space.Compact>
          <Form.Item label="from" name="ratioMin" rules={[{ required: true, message: "This field is required" }]}>
            <InputNumber min={10} max={80} step={10} />
          </Form.Item>
          <Form.Item label="to" name="ratioMax" rules={[{ required: true, message: "This field is required" }]}>
            <InputNumber min={20} max={90} step={10} />
          </Form.Item>
        </Space.Compact>
        <Text>Position exit levels range (%)</Text>
        <Space.Compact>
          <Form.Item label="higher" name="exitTriggerMin" rules={[{ required: true, message: "This field is required" }]}>
            <InputNumber min={10} max={80} step={10} />
          </Form.Item>
          <Form.Item label="lower" name="exitTriggerMax" rules={[{ required: true, message: "This field is required" }]}>
            <InputNumber min={20} max={90} step={10} />
          </Form.Item>
        </Space.Compact>
        <Text>Channel width range</Text>
        <Space.Compact>
          <Form.Item label="from" name="channelWidthMin" rules={[{ required: true }]}>
            <InputNumber min={1} max={99} addonAfter="%" />
          </Form.Item>
          <Form.Item label="to" name="channelWidthMax" rules={[{ required: true }]}>
            <InputNumber min={2} max={99} addonAfter="%" />
          </Form.Item>
        </Space.Compact>
        <Text>Fee APY for range above</Text>
        <Space.Compact>
          <Form.Item label="narrow channel" name="apyMin" rules={[{ required: true }]}>
            <InputNumber min={1} addonAfter="%" />
          </Form.Item>
          <Form.Item label="wide channel" name="apyMax" rules={[{ required: true }]}>
            <InputNumber min={1} addonAfter="%" />
          </Form.Item>
        </Space.Compact>
      </Space>
      <Form.Item label="Swap fee" name="swapFee">
        <Radio.Group>
          <Radio.Button value="100">0.01%</Radio.Button>
          <Radio.Button value="500">0.05%</Radio.Button>
          <Radio.Button value="3000">0.3%</Radio.Button>
          <Radio.Button value="10000">1%</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Position placement and swap network fee" name="poolChangePrice" rules={[{ required: true }]}>
        <InputNumber addonAfter="$" step="0.01" />
      </Form.Item>
      <Form.Item label="Switch direction" valuePropName="checked" name="switchDirection">
          <Switch />
      </Form.Item>
      <Form.Item label="Center entry to range" valuePropName="checked" name="center">
          <Switch />
      </Form.Item>
      <Space direction="vertical">
        <Text>Pool change count range</Text>
          <Space.Compact>
            <Form.Item label="from" name="poolChangeCountRangeMin" rules={[{ required: false }]}>
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item label="to" name="poolChangeCountRangeMax" rules={[{ required: false }]}>
              <InputNumber min={2} />
            </Form.Item>
          </Space.Compact>
        </Space>
      <Form.Item>
        <Button type="primary"
            htmlType="submit"
            size="small"
            disabled={loading || !tokenFirstValue || !tokenSecondValue || !isFormValid}
            style={{ width: 90 }}>Get report</Button>
      </Form.Item>
    </Form>
    </Flex>
    {reportData && <Table
          columns={mainColumns}
          loading={loading}
          rowKey={record => `${record.channelWidth}-${record.ratio}-${record.poolFeesIncome}-${record.contractCallFees}-${record.timeframe}`}
          expandable={{expandedRowRender, defaultExpandedRowKeys:[] }}
          dataSource={reportData} />
        }
    </>
  );
};